<template>
  <UserTop :title="title" @userInfo="getUserInfo">

    <div class="pro-box" v-if="questionList && questionList.length > 0">
      <div class="question-item" v-for="(item,index) in questionList" :key="index">
        <div>
          <span class="ask">问</span>
          <p class="title">{{item.question}}</p>
        </div>
        <div>
          <span>答</span>
          <p>{{item.answer}}</p>
        </div>
      </div>
    </div>

  </UserTop>
</template>
<script>
import CommonApi from "@/api/common"
import UserTop from "@/views/user_promot/components/userTop/index.vue"
export default {
  name: 'commonProblem',
  components: {
    UserTop
  },
  data() {
    return {
      title: '常见问题',
      userInfo: {
        isLogin: false, // 用户登录状态
        username: '',
        nickname: '',
        token: ''
      },
      questionList: []
    }
  },
  created() {
    this.postQuestion()
  },
  methods: {
    getUserInfo(msg){
      if (msg && msg.user === false){
        this.userInfo = this.$options.data().userInfo
      }else {
        this.userInfo = msg.info
      }
    },
    // 获取常见问题
    postQuestion(){
      CommonApi.question().then(res => {
        this.questionList = res.data
      }).catch(() => {
      })
    }
  }
}
</script>
<style scoped lang="less">
.pro-box{
  width: 100%;
  padding: 12px;
  border-radius: 15px;
  border: 1px solid #ffffff;
  box-shadow: 0 0 15px rgba(81, 59, 248, 0.1);
  background-color: rgba(255, 255, 255, 0.3);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  .question-item{
    width: 100%;
    margin-bottom: 10px;
    border-bottom: 1px dashed #e3e3e3;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    >div{
      margin-bottom: 10px;
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: flex-start;
      span{
        width: 28px;
        height: 28px;
        line-height: 28px;
        background-color: #d4dbff;
        border-radius: 5px;
        text-align: center;
        font-size: 12px;
        color: #ffffff;
        margin-right: 16px;
      }
      span.ask{
        background-color: #909fff;
      }
      p{
        flex: 1;
        line-height: 22px;
        font-size: 12px;
      }
      p.title{
        font-weight: bold;
        font-size: 12px;
        color: #909fff;
      }
    }
  }
  .question-item:last-child{
    border-bottom: 0;
  }
}
</style>
